import React, { Fragment } from 'react';
import Helmet from 'react-helmet';
// import LogRocket from 'logrocket';
// import setupLogRocketReact from 'logrocket-react';
import {ContextProviderComponent} from "../context/CheckoutContext"
import "../master.scss"

// console.log(process.env.NODE_ENV)
// if (process.env.NODE_ENV === 'production') {
//   try {
//     LogRocket.init(process.env.GATSBY_LOGROCKET);
//     setupLogRocketReact(LogRocket);
//   } catch (e) { }
// }


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <section class="section"><div class="container"><div class="block"><h1 class="title">Notika sistēmas kļūda</h1><p><a href="" class="button is-primary">Pārlādēt lapu</a></p></div></div></section>;
    }

    return this.props.children; 
  }
}


export default ({ children }) => (
  <ContextProviderComponent>
    <Helmet
      key="app-head"
      titleTemplate={`%s — Heyday`}
      defaultTitle={'Heyday'}
    >
      <html lang="lv" />
      <meta name="description" content={''} />
      <meta name="release" content={process.env.GATSBY_RELEASE_VERSION} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
    </Helmet>
    <div>
      <ErrorBoundary>
         {children}
      </ErrorBoundary>
    </div>
  </ContextProviderComponent>
);