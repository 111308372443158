import React from "react"

const defaultState = {
  plan: "1x", // also set reset values!
  setPlan: () => { },

  delivery: "riga",
  setDelivery: () => { },
  toggleDelivery: () => { },

  // countryCode: null,
  phone: null,
  deviceToken: null,
  // setCountryCode: () => {},
  setPhone: () => { },
  setDeviceToken: () => { },

  sessionToken: null,
  setSessionToken: () => { },


  gift: false,
  giftCountryCode: '371',
  giftPhone: null,
  giftName: null,
  setGift: () => {},
  setCountryCode: () => {},
  setGiftPhone: () => {},
  setGiftName: () => {},

}

const CheckoutContext = React.createContext(defaultState)


class ContextProviderComponent extends React.Component {
  state = {
    ...defaultState
  }

  // plans = {
  //   '4x': {
  //     priceKey: 'price_1IFOizINDHQ5bsd6k2pMskex',
  //     price: 35,
  //     period: 'Reizi nedēļā'
  //   },
  //   '2x': {
  //     priceKey: 'price_1IFOizINDHQ5bsd6UtLSkB7h',
  //     price: 35,
  //     period: 'Reizi divās nedēļās'
  //   },
  //   '1x': {
  //     priceKey: 'price_1IH7tIINDHQ5bsd6gedlGRqi',
  //     price: 35,
  //     period: 'Reizi mēnesī'
  //   }
  // }

  plans = {
    '4x': {
      riga: {
        priceKey: process.env.GATSBY_4XRIGA,
        price: 35,
        frequency: 4,
        deliveryPrice: 0,
        monthlyPrice: 140,
        period: 'Reizi nedēļā'
      },
      other: {
        priceKey: process.env.GATSBY_4XOTHER,
        // priceKey: 'price_1IH7wBINDHQ5bsd6CequDIQe',
        price: 35,
        frequency: 4,
        deliveryPrice: 5,
        monthlyPrice: 140,
        period: 'Reizi nedēļā'
      }
    },
    '2x': {
      riga: {
        priceKey: process.env.GATSBY_2XRIGA,
        price: 35,
        deliveryPrice: 0,
        frequency: 2,
        monthlyPrice: 70,
        period: 'Divas reizes mēnesī'
      },
      other: {
        priceKey: process.env.GATSBY_2XOTHER,
        // priceKey: 'price_1IH7wYINDHQ5bsd6UrvYvapE',
        price: 35,
        deliveryPrice: 5,
        frequency: 2,
        monthlyPrice: 70,
        period: 'Divas reizes mēnesī'
      }
    },
    '1x': {
      riga: {
        priceKey: process.env.GATSBY_1XRIGA,
        price: 35,
        deliveryPrice: 0,
        frequency: 1,
        monthlyPrice: 35,
        period: 'Vienu reizi mēnesī'
      },
      other: {
        priceKey: process.env.GATSBY_1XOTHER,
        // priceKey: 'price_1IH7wtINDHQ5bsd6HR83rzAa',
        price: 35,
        deliveryPrice: 5,
        frequency: 1,
        monthlyPrice: 35,
        period: 'Vienu reizi mēnesī'
      }
    }
  }
  // deliveryPlans = {
  //   '4x': {
  //     riga: {
  //       priceKey: 'price_1IH3NZINDHQ5bsd6PdPwjNhj',
  //       price: 0,
  //     },
  //     other: {
  //       priceKey: 'price_1IH3NZINDHQ5bsd6NfWTYqoc',
  //       price: 5,
  //     }
  //   },
  //   '2x': {
  //     riga: {
  //       priceKey: 'price_1IH3NZINDHQ5bsd6tLIIC8el',
  //       price: 0,
  //     },
  //     other: {
  //       priceKey: 'price_1IH3NZINDHQ5bsd6QChvUNG4',
  //       price: 5,
  //     }
  //   },
  //   '1x': {
  //     riga: {
  //       priceKey: 'price_1IH3NZINDHQ5bsd6Goa92gxL',
  //       price: 0,
  //     },
  //     other: {
  //       priceKey: 'price_1IH3NZINDHQ5bsd67IPJYZe6',
  //       price: 5,
  //     }
  //   }
  // }


  getPlanDetails = () => {
    return (this.plans[this.state.plan][this.state.delivery])
  }

  getDeliveryDetails = () => {
    return (this.plans[this.state.plan][this.state.delivery])
  }


  setPlan = (plan) => {
    localStorage.setItem("plan", JSON.stringify(plan))
    this.setState({ plan: plan })
  }

  setDelivery = (delivery) => {
    localStorage.setItem("delivery", JSON.stringify(delivery))
    this.setState({ delivery: delivery })
  }
  toggleDelivery = () => {
    console.log('context.toggleDelivery', this.state.delivery)
    this.state.delivery == 'riga' ? this.setDelivery('other') : this.setDelivery('riga')
  }

  // setCountryCode = (countryCode) => {
  //   localStorage.setItem("countryCode", JSON.stringify(countryCode))
  //   this.setState({countryCode: countryCode})
  // }

  setPhone = (phone) => {
    localStorage.setItem("phone", JSON.stringify(phone))
    this.setState({ phone: phone })
  }

  setDeviceToken = (deviceToken) => {
    localStorage.setItem("deviceToken", JSON.stringify(deviceToken))
    this.setState({ deviceToken: deviceToken })
  }

  setSessionToken = (sessionToken) => {
    localStorage.setItem("sessionToken", JSON.stringify(sessionToken))
    this.setState({ sessionToken: sessionToken })
  }

  setGift = (gift) => {
    localStorage.setItem("gift", JSON.stringify(gift))
    this.setState({ gift: gift })
  }
  setGiftCountryCode = (giftCountryCode) => {
    localStorage.setItem("giftCountryCode", JSON.stringify(giftCountryCode))
    this.setState({ giftCountryCode: giftCountryCode })
  }
  setGiftPhone = (giftPhone) => {
    localStorage.setItem("giftPhone", JSON.stringify(giftPhone))
    this.setState({ giftPhone: giftPhone })
  }
  setGiftName = (giftName) => {
    localStorage.setItem("giftName", JSON.stringify(giftName))
    this.setState({ giftName: giftName })
  }

  
  resetAll = () => {
    this.setPlan('1x');
    this.setDelivery('riga');
    this.setPhone(null);
    this.setDeviceToken(null);
    this.setSessionToken(null);

    this.setGift(false);
    this.setGiftCountryCode('371');
    this.setGiftPhone(null);
    this.setGiftName(null);
  }
  componentDidMount() {
    const plan = JSON.parse(localStorage.getItem("plan"))
    if (plan) { this.setState({ plan: plan }) }

    const delivery = JSON.parse(localStorage.getItem("delivery"))
    if (delivery) { this.setState({ delivery: delivery }) }

    // const countryCode = JSON.parse(localStorage.getItem("countryCode"))
    // if (countryCode) { this.setState({ countryCode: countryCode }) }

    const phone = JSON.parse(localStorage.getItem("phone"))
    if (phone) { this.setState({ phone: phone }) }

    const deviceToken = JSON.parse(localStorage.getItem("deviceToken"))
    if (deviceToken) { this.setState({ deviceToken: deviceToken }) }

    const sessionToken = JSON.parse(localStorage.getItem("sessionToken"))
    if (sessionToken) { this.setState({ sessionToken: sessionToken }) }


    const gift = JSON.parse(localStorage.getItem("gift"))
    if (gift) { this.setState({ gift: gift }) }

    const giftCountryCode = JSON.parse(localStorage.getItem("giftCountryCode"))
    if (giftCountryCode) { this.setState({ giftCountryCode: giftCountryCode }) }

    const giftPhone = JSON.parse(localStorage.getItem("giftPhone"))
    if (giftPhone) { this.setState({ giftPhone: giftPhone }) }

    const giftName = JSON.parse(localStorage.getItem("giftName"))
    if (giftName) { this.setState({ giftName: giftName }) }
  }

  render() {
    return (

      <CheckoutContext.Provider value={{
        ...this.state,
        // setters
        setPlan: this.setPlan,
        setDelivery: this.setDelivery,
        toggleDelivery: this.toggleDelivery,
        // setCountryCode: this.setCountryCode, 
        setPhone: this.setPhone,
        setDeviceToken: this.setDeviceToken,
        setSessionToken: this.setSessionToken,

        setGift: this.setGift,
        setGiftCountryCode: this.setGiftCountryCode,
        setGiftPhone: this.setGiftPhone,
        setGiftName: this.setGiftName,

        // getters
        getPlanDetails: this.getPlanDetails,
        getDeliveryDetails: this.getDeliveryDetails,

        // methods
        resetAll: this.resetAll
      }}>
        {this.props.children}
      </CheckoutContext.Provider>
    )
  }
}

const { Consumer } = CheckoutContext

export { Consumer as default, CheckoutContext, ContextProviderComponent }
