// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-delivery-js": () => import("./../../../src/pages/delivery.js" /* webpackChunkName: "component---src-pages-delivery-js" */),
  "component---src-pages-hi-1-js": () => import("./../../../src/pages/hi/1.js" /* webpackChunkName: "component---src-pages-hi-1-js" */),
  "component---src-pages-hi-10-js": () => import("./../../../src/pages/hi/10.js" /* webpackChunkName: "component---src-pages-hi-10-js" */),
  "component---src-pages-hi-11-js": () => import("./../../../src/pages/hi/11.js" /* webpackChunkName: "component---src-pages-hi-11-js" */),
  "component---src-pages-hi-12-js": () => import("./../../../src/pages/hi/12.js" /* webpackChunkName: "component---src-pages-hi-12-js" */),
  "component---src-pages-hi-13-js": () => import("./../../../src/pages/hi/13.js" /* webpackChunkName: "component---src-pages-hi-13-js" */),
  "component---src-pages-hi-14-js": () => import("./../../../src/pages/hi/14.js" /* webpackChunkName: "component---src-pages-hi-14-js" */),
  "component---src-pages-hi-15-js": () => import("./../../../src/pages/hi/15.js" /* webpackChunkName: "component---src-pages-hi-15-js" */),
  "component---src-pages-hi-2-js": () => import("./../../../src/pages/hi/2.js" /* webpackChunkName: "component---src-pages-hi-2-js" */),
  "component---src-pages-hi-3-js": () => import("./../../../src/pages/hi/3.js" /* webpackChunkName: "component---src-pages-hi-3-js" */),
  "component---src-pages-hi-4-js": () => import("./../../../src/pages/hi/4.js" /* webpackChunkName: "component---src-pages-hi-4-js" */),
  "component---src-pages-hi-5-js": () => import("./../../../src/pages/hi/5.js" /* webpackChunkName: "component---src-pages-hi-5-js" */),
  "component---src-pages-hi-6-js": () => import("./../../../src/pages/hi/6.js" /* webpackChunkName: "component---src-pages-hi-6-js" */),
  "component---src-pages-hi-7-js": () => import("./../../../src/pages/hi/7.js" /* webpackChunkName: "component---src-pages-hi-7-js" */),
  "component---src-pages-hi-8-js": () => import("./../../../src/pages/hi/8.js" /* webpackChunkName: "component---src-pages-hi-8-js" */),
  "component---src-pages-hi-9-js": () => import("./../../../src/pages/hi/9.js" /* webpackChunkName: "component---src-pages-hi-9-js" */),
  "component---src-pages-hi-index-js": () => import("./../../../src/pages/hi/index.js" /* webpackChunkName: "component---src-pages-hi-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manage-js": () => import("./../../../src/pages/manage.js" /* webpackChunkName: "component---src-pages-manage-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */)
}

